import React from "react"
import { graphql } from "gatsby"

import Img, { FluidObject } from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Link from "../components/Link"
import Column from "../components/Column"
import Row from "../components/Row"
import Wrapper from "../components/Wrapper"

import styles from "./index.module.scss"
import { BodyLarge, H1 } from "../components/Typography"

type Props = {
  data: {
    leaf: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const NotFoundPage = ({ data }: Props) => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Wrapper className={styles.pageContent}>
        <Row center middle>
          <Column mobile={8} desktop={6} className={styles.textContainer}>
            <H1 className={styles.title}>Page Not Found</H1>
            <BodyLarge>Unfortunately, this page could not be found.</BodyLarge>
            <Link to="/" className={styles.link}>
              Click here to return to the home page.
            </Link>
          </Column>
          <Column mobile={8} desktop={8} className={styles.imageContainer}>
            <Img
              fluid={data.leaf.childImageSharp.fluid}
              alt="leaf"
              className={styles.image}
            />
          </Column>
        </Row>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    leaf: file(relativePath: { eq: "leaf.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
